import React, { useState } from 'react';
import getLanguage from 'components/helpers/getLanguage';

import { logout } from 'redux/login/loginSlice';
import { showResetPinForm } from 'redux/reset-pin/resetPinSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import translationKeys from 'translations/translationKeys';
import {
  addCombToBetSlip,
  calculateOdds, addCouponsToBetSlip,
  updateStake,
  applyBonus, calculateGains, clearBetSlip,
} from 'redux/configs/betConfigsSlice';
import PropTypes from 'prop-types';
import {
  authParams, getBase64Hash,
} from 'components/helpers/base64AuthParams';
import { userObj } from 'components/common/requestBody';
import baseUrls from '../../../config';
import person from '../../../assets/images/mathys/svg/person.svg';
import plus from '../../../assets/images/mathys/svg/plus-lg.svg';

const CodeBetForm = ({
  setAlertMessage, setDisplayMessage,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { stake, minStake, bets } = useSelector((store) => store.betSlip);
  const [showCombinationForm, setShowCombinationForm] = useState(true);
  const { agentId } = useSelector((store) => store.login);
  const [language, setLanguage] = useState(getLanguage());

  const {
    msg82, msg80, msg81, msg83, msg139, msg168, msg169,
  } = translationKeys;

  const getCombination = async (body, isShorted = false) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const apiBaseUrl = baseUrls.BASE_URL_API;
    const apiVersion2 = baseUrls.API_VERSION2;
    const api = `/ws/sport/combination/info?isShorted=${isShorted}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const signatureParam = `/ws/sport/combination/infoisShorted=${isShorted}`;
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${signatureParam}${JSON.stringify(body)}`)}`,
      Authorization: token ? `Bearer ${token}` : '',
    };
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error('Response not OK');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  const loadCoupon = async (coupon) => {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const apiBaseUrl = baseUrls.BASE_URL_API;
    const apiVersion2 = baseUrls.API_VERSION2;
    const api = `/coupon/${coupon}?vers=${userObj.ver}`;
    const sigParam = `/coupon/${coupon}vers=${userObj.ver}`;
    const url = `${apiBaseUrl}/${apiVersion2}${api}`;
    const headers = {
      'Content-Type': 'application/json; charset=UTF-8',
      'Content-Signature': `${authParams.username}:${getBase64Hash(`${authParams.username}/${apiVersion2}${sigParam}`)}`,
      Authorization: token ? `Bearer ${token}` : '',
    };

    try {
      const response = await fetch(url, { headers });
      if (!response.ok) {
        throw new Error('Response not OK');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };
  const {
    msg41, msg42, msg143, msg29,
  } = translationKeys;

  const clearMessage = () => {
    setTimeout(() => {
      setDisplayMessage(false);
      setAlertMessage('');
    }, 3000);
  };

  const handleCombinationSubmit = (e) => {
    e.preventDefault();
    const pickValue = e.target.elements.combination.value;
    const pickRegex = /^\d+\*[^*]+(?:\*\d+){0,2}$/;
    if (!pickRegex.test(pickValue)) {
      setDisplayMessage(true);
      setAlertMessage(t(msg41));
      clearMessage();
      return;
    }

    const length = pickValue?.split('*').length;
    const isShorted = (length && length === 2) ?? false;

    const param = {
      channel: userObj.chl,
      pick: pickValue,
      language: getLanguage(),
    };

    getCombination(param, isShorted).then((match) => {
      if (match) {
        const data = match?.data;
        let msg = match?.msg;

        if (data?.outcome?.length === 0) {
          msg = msg139;
        }

        const matchFound = data && data.outcome.length > 0
          ? bets.some((item) => item.uniqueId
        === (data.id + data.outcome[0].name + data.outcome[0].market.name))
          : false;

        if (matchFound) {
          setDisplayMessage(true);
          setAlertMessage(t(msg42));
          clearMessage();
        } else if (msg) {
          setDisplayMessage(true);
          setAlertMessage(t(msg));
          clearMessage();
        } else {
          document.getElementById('combination-form').reset();
          dispatch(addCombToBetSlip({
            match: data,
            odds: data?.outcome[0]?.odds ?? '',
            team: data?.outcome[0]?.name ?? '',
            market: data?.outcome[0]?.market?.name ?? '',
            matchId: data?.id ?? '',
            id: data?.outcome[0]?.id ?? '',
            uniqueId: data?.id + data?.outcome[0]?.name + data?.outcome[0]?.market?.name,
            matchNumber: data?.number ?? 0,
            pick: `${data?.number}*${data?.outcome[0]?.market?.id}*${data?.outcome[0]?.outcome_id}`,
          }));
          dispatch(updateStake(stake === 0 ? minStake : stake));
          dispatch(calculateOdds());
          dispatch(calculateGains());
          dispatch(applyBonus());
          setDisplayMessage(false);
          setAlertMessage('');
        }
      } else {
        setDisplayMessage(true);
        setAlertMessage(t(msg29));
        clearMessage();
      }
    });
  };

  const createBetList = (choices) => choices
    .map(({ match, propositions }) => propositions.map((p) => ({
      match,
      odds: p.odds,
      team: p.name,
      market: p.market.name,
      matchId: match.id,
      id: p.id,
      uniqueId: match.id + p.name + p.market.name,
      matchNumber: match.number,
      pick: `${match.number}*${p.market.id}*${p.outcome_id}`,
    }))).reduce((acc, betList) => acc.concat(betList), []);

  const hasMatch = (bets, betPropositions) => bets
    .some((item) => betPropositions
      .some((betProposition) => betProposition.uniqueId === item.uniqueId));

  const handleCouponSubmit = (e) => {
    e.preventDefault();
    const couponValue = e.target.elements.coupon.value;
    if (!couponValue) {
      setDisplayMessage(true);
      setAlertMessage(t(msg143));
      clearMessage();
      return;
    }

    loadCoupon(couponValue).then((response) => {
      if (response) {
        let arr = [];
        const msg = response?.msg;
        const data = response?.data;
        let matchFound;
        const betChoices = data?.betChoices || [];
        const bet = data?.bet;
        let betType = 'SIMPLE';
        let stake = bet?.amt;

        switch (Number(bet?.tkt || 1)) {
          case 2:
            betType = 'MULTIPLE';
            break;
          case 3:
            betType = 'INTEGRAL';
            break;
          case 5:
            betType = 'MULTI_SIMPLE';
            stake = stake.toLowerCase().split('s');
            break;
          default:
            betType = 'SIMPLE';
            break;
        }

        if (betChoices.length > 0) {
          arr = createBetList(betChoices);
          matchFound = hasMatch(bets, arr);
        }
        if (matchFound) {
          setDisplayMessage(true);
          setAlertMessage(t(msg42));
          clearMessage();
        } else if (msg) {
          setDisplayMessage(true);
          setAlertMessage(t(msg));
          clearMessage();
        } else {
          document.getElementById('coupon-form').reset();
          dispatch(addCouponsToBetSlip({ bets: arr, betType }));
          if (Array.isArray(stake)) {
            stake.forEach((value, index) => {
              dispatch(updateStake({ stake: value, index }));
            });
          } else {
            dispatch(updateStake(stake ?? minStake));
          }
          dispatch(calculateOdds());
          dispatch(calculateGains());
          dispatch(applyBonus());
          setDisplayMessage(false);
          setAlertMessage('');
        }
      } else {
        setDisplayMessage(true);
        setAlertMessage(t(msg29));
        clearMessage();
      }
    });
  };
  const toggleForm = (e) => {
    setDisplayMessage(false);
    setAlertMessage('');
    dispatch(clearBetSlip());
    if (e.target.value === 'combination') {
      setShowCombinationForm(true);
      setTimeout(() => {
        document.getElementById('combination-form').reset();
      }, 100);
    } else {
      setShowCombinationForm(false);
      setTimeout(() => {
        document.getElementById('coupon-form').reset();
      }, 100);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };
  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
    localStorage.setItem('lang', e.target.value);
  };
  const userLanguage = navigator.language.split('-')[0];

  return (
    <div className="collapse navbar-collapse">
      <ul className="navbar-nav mb-2 mb-lg-0">
        <li className="nav-item dropdown">
          <div
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <img src={person} alt="" />
            Agent
            {' '}
            {agentId}
          </div>
          <ul className="dropdown-menu">
            <li>
              <button
                className="dropdown-item text-danger"
                type="button"
                onClick={() => {
                  dispatch(logout());
                  localStorage.setItem('lang', userLanguage);
                  window.location.reload();
                }}
              >
                {t(msg80)}
              </button>
            </li>
            <li>
              <button
                type="button"
                className="dropdown-item"
                onClick={() => {
                  dispatch(showResetPinForm());
                }}
              >
                {t(msg81)}
              </button>
            </li>
          </ul>
        </li>
      </ul>
      <select
        className="form-select"
        id="select-date"
        value={language}
        onChange={handleLanguageChange}
      >
        <option value="fr">FR</option>
        <option value="en">EN</option>
      </select>
      <select
        className="form-select"
        id="select-comb"
        onChange={toggleForm}
        defaultValue="combination"
      >
        <option value="combination">{t(msg82)}</option>
        <option value="code-coupon">{t(msg83)}</option>
      </select>
      {showCombinationForm ? (
        <form
          id="combination-form"
          className="top-bar-form"
          onSubmit={handleCombinationSubmit}
        >
          <input
            className="form-control"
            placeholder={t(msg168)}
            defaultValue=""
            name="combination"
            id="combination"
            type="text"
          />
          <button
            className="btn btn-secondary"
            type="submit"
          >
            <img src={plus} alt="" />
          </button>
        </form>
      ) : (
        <form
          id="coupon-form"
          className="top-bar-form"
          onSubmit={handleCouponSubmit}
        >
          <input
            className="form-control"
            defaultValue=""
            placeholder={t(msg169)}
            name="coupon"
            id="coupon"
            type="text"
          />
          <button
            className="btn btn-secondary"
            type="submit"
          >
            <img src={plus} alt="" />
          </button>
        </form>
      )}

    </div>
  );
};

CodeBetForm.propTypes = {
  setAlertMessage: PropTypes.func.isRequired,
  setDisplayMessage: PropTypes.func.isRequired,
};

export default CodeBetForm;
